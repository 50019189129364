import { Menu, Input, ActionIcon, Tooltip } from '@mantine/core'

import { toast } from 'src/components/Overlays/Toast/Toast'
import { LinkTypeDisplay } from 'src/graphql/types/trackingLinks'
import IconLink from 'src/icons/IconLink'
import IconThreeDots from 'src/icons/IconThreeDots'

const TrackingLink = ({ link, handleDeleteModal }) => {
  const generateLink = () => {
    const uniqueLink = link.link
    const origin = window.location.origin

    return `${origin}/short-form/${uniqueLink}`
  }

  const uniqueLink = generateLink()

  const handleCopy = () => {
    navigator.clipboard.writeText(uniqueLink).then(
      () => {
        toast('Link copied to clipboard', 'success')
      },
      () => {
        toast('Something went wrong', 'error')
      }
    )
  }

  const linkTypeDisplay = LinkTypeDisplay[link.linkType] || link.linkType

  return (
    <div
      className=" grid grid-cols-12 items-center gap-2"
      key={link.trackingLinkId}
    >
      <div className="col-span-4 text-sm">
        <div className="font-semibold capitalize text-doubleNickel-gray-600">
          {linkTypeDisplay}
          {link.linkName?.trim() && ':'}
        </div>
        {link.linkName?.trim() && (
          <Tooltip label={link.linkName?.trim()} withArrow>
            <div className="truncate font-normal text-doubleNickel-gray-900">
              {link.linkName?.trim()}
            </div>
          </Tooltip>
        )}
      </div>
      <Input
        className="col-span-6 rounded-lg"
        variant="filled"
        disabled
        placeholder="Input component"
        value={uniqueLink}
      />
      <Tooltip label={'Copy link'} withArrow className="text-xs">
        <ActionIcon
          variant="subtle"
          className="col-span-1 h-6 w-6"
          onClick={handleCopy}
        >
          <IconLink className="fill-doubleNickel-white stroke-doubleNickel-brand-500" />
        </ActionIcon>
      </Tooltip>
      <Menu shadow="md" position="bottom-end">
        <Menu.Target>
          <Tooltip label={'More actions'} withArrow className="text-xs">
            <ActionIcon variant="subtle" className="col-span-1 h-6 w-6">
              <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>
        <Menu.Dropdown className="w-40">
          <Menu.Item
            className="text-doubleNickel-gray-700"
            onClick={() => handleDeleteModal(link.trackingLinkId)}
          >
            Delete
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </div>
  )
}

export default TrackingLink
