import { useContext, useEffect, useRef, useState } from 'react'

import { LoadingOverlay, Badge, Box } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { useQuery, MetaTags } from '@redwoodjs/web'

import Button from 'src/components/Buttons/Button/Button'
import PaginationArea from 'src/components/Navigation/PaginationArea/PaginationArea'
import CheckboxMenu from 'src/components/Overlays/CheckboxMenu/CheckboxMenu'
import { GET_JOB_LISTINGS } from 'src/graphql/joblistings.graphql'
import { usePage } from 'src/hooks/usePage/usePage'
import IconAdd from 'src/icons/IconAdd'
import IconClose from 'src/icons/IconClose'
import IconFilter from 'src/icons/IconFilter'

import CreateJobListingModal from './components/CreateJobListingModal/CreateJobListingModal'
import JobsListingTable from './components/JobsListingTable/JobsListingTable'
import { RecruitersContext } from 'src/context/RecruitersContext'

const JobsPage = () => {
  const [opened, { open, close }] = useDisclosure(false)
  const recruiters = useContext(RecruitersContext)
  const buttonRef = useRef(null)
  const [selectedRecruiters, setSelectedRecruiters] = useState([])
  const [page, setPage] = usePage()
  const LIMIT = 12

  /**********************    QUERIES    **********************/

  const { data, loading } = useQuery(GET_JOB_LISTINGS, {
    variables: {
      pageInfo: { offset: (page - 1) * LIMIT, limit: LIMIT },
      filters: {
        recruiters: selectedRecruiters.map((recruiter) => recruiter.value),
      },
    },
  })
  const jobsData = data?.jobListings?.items ?? []

  /**********************    FUNCTIONS    **********************/
  const removeRecruiterFilter = (filter) => {
    let updatedRecruiters = [...selectedRecruiters]
    updatedRecruiters = updatedRecruiters.filter(
      (r) => r.label !== filter.label
    )
    setSelectedRecruiters(updatedRecruiters)
  }

  useEffect(() => {
    setPage(1)
  }, [selectedRecruiters])

  return (
    <div className="flex h-full w-full flex-col gap-5">
      {opened && (
        <CreateJobListingModal
          isModalOpen={opened}
          onModalClose={close}
          recruiters={recruiters}
        />
      )}
      <MetaTags title="Jobs" description="Jobs page" />
      <div className="flex h-full flex-col gap-5 px-5 pb-3">
        <div className="flex flex-col gap-2 ">
          <div className="flex flex-1  flex-row ">
            <div className="flex flex-col">
              <div className="text-lg font-semibold text-doubleNickel-gray-900">
                Job Listings
              </div>
              <div className="text-sm text-doubleNickel-gray-600">
                {/* These spans are necessary to prevent Google Translate issues  */}
                <span>{data?.jobListings.totalCount}</span>
                <span> job listings</span>
              </div>
            </div>
            <div className="flex flex-1 flex-row items-center justify-end gap-4">
              <CheckboxMenu
                items={recruiters}
                selectedItems={selectedRecruiters}
                setSelectedItems={setSelectedRecruiters}
                fallbackText={'No recruiters found'}
              >
                <Button
                  ref={buttonRef}
                  variant="outline"
                  text={`Recruiter: ${
                    selectedRecruiters.length === 0
                      ? 'All'
                      : selectedRecruiters.length
                  }`}
                  lefticon={<IconFilter />}
                />
              </CheckboxMenu>
              <Button
                text="Create Job Listing"
                lefticon={<IconAdd />}
                onClick={open}
              />
            </div>
          </div>
          {selectedRecruiters.length > 0 && (
            <div className="flex flex-row items-center gap-4">
              {selectedRecruiters.map((recruiter) => (
                <Badge
                  key={recruiter.value}
                  onClick={() => removeRecruiterFilter(recruiter)}
                  rightSection={
                    <IconClose className="fill-none stroke-doubleNickel-gray-400" />
                  }
                  className="h-7 border  border-doubleNickel-gray-200 bg-doubleNickel-gray-50 text-sm font-medium capitalize text-doubleNickel-gray-700"
                >
                  {recruiter.label}
                </Badge>
              ))}
              <Button
                variant="subtle"
                text="Clear all"
                onClick={() => setSelectedRecruiters([])}
              />
            </div>
          )}
        </div>
        <Box className="flex h-full flex-col overflow-auto" pos={'relative'}>
          <JobsListingTable jobsData={jobsData} loading={loading} />
          <LoadingOverlay
            visible={loading}
            overlayProps={{ opacity: 0 }}
            zIndex={1000}
          />
        </Box>
        <PaginationArea
          page={page}
          totalPages={Math.ceil(data?.jobListings.totalCount / LIMIT)}
          onPageChange={setPage}
        />
      </div>
    </div>
  )
}

export default JobsPage
