import React from 'react'

import { Tooltip, Avatar } from '@mantine/core'

export interface Avatar {
  firstName: string
  lastName: string
}

interface AvatarGroupProps {
  avatars: Avatar[]
  threshold?: number
}

const AvatarGroup: React.FC<AvatarGroupProps> = ({
  avatars = [],
  threshold = 5,
}) => {
  return (
    <Avatar.Group spacing="xs">
      {avatars.slice(0, threshold).map((avatar, index) => (
        <Tooltip
          key={index}
          label={`${avatar.firstName} ${avatar.lastName}`}
          withArrow
        >
          <Avatar
            size={30}
            variant="transparent"
            classNames={{
              placeholder:
                'bg-doubleNickel-brand-25 text-doubleNickel-brand-500',
            }}
          >
            {`${avatar.firstName.charAt(0)}${avatar.lastName.charAt(0)}`}
          </Avatar>
        </Tooltip>
      ))}

      {avatars.length > threshold && (
        <Tooltip
          withArrow
          label={
            <>
              {avatars.slice(threshold).map((avatar) => (
                <div
                  key={avatar.lastName}
                >{`${avatar.firstName} ${avatar.lastName}`}</div>
              ))}
            </>
          }
        >
          <Avatar
            size={30}
            variant="transparent"
            classNames={{
              placeholder:
                'bg-doubleNickel-brand-25 text-doubleNickel-brand-500',
            }}
          >
            +{avatars.length - threshold}
          </Avatar>
        </Tooltip>
      )}
    </Avatar.Group>
  )
}

export default AvatarGroup
