import { useState } from 'react'

import { Table, Tooltip, ActionIcon, Menu } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'

import { Link, routes } from '@redwoodjs/router'
import { useMutation } from '@redwoodjs/web'

import AvatarGroup from 'src/components/DataDisplay/AvatarGroup/AvatarGroup'
import StyledBadge from 'src/components/DataDisplay/StyledBadge/StyledBadge'
import DeleteModal from 'src/components/Overlays/DeleteModal/DeleteModal'
import { toast } from 'src/components/Overlays/Toast/Toast'
import {
  DELETE_JOB_LISTING,
  GET_JOB_LISTINGS,
} from 'src/graphql/joblistings.graphql'
import IconDocument from 'src/icons/IconDocument'
import IconEmptyInbox from 'src/icons/IconEmptyInbox'
import IconThreeDots from 'src/icons/IconThreeDots'
import IconUsers from 'src/icons/IconUsers'
import { formatAddress } from 'src/lib/address.utils'
import { formatDate } from 'src/lib/formatters'

import JobListingDetailsModal from '../JobListingDetailsModal/JobListingDetailsModal'

const JobsListingTable = ({ jobsData, loading }) => {
  const [openedDetails, detailHandlers] = useDisclosure(false)
  const [selectedJob, setSelectedJob] = useState(null)
  const [openedDelete, deleteHandlers] = useDisclosure(false)

  const handleMenu = (event, job) => {
    event.stopPropagation()
    setSelectedJob(job)
    deleteHandlers.open()
  }

  const handleSelectedJob = (event, job) => {
    if (event.target && event.target.closest('.no-trigger')) {
      // If so, do nothing more (do not open the details modal)
      return
    }

    setSelectedJob(job)
    detailHandlers.open()
  }

  const [deleteJobListing] = useMutation(DELETE_JOB_LISTING, {
    refetchQueries: [GET_JOB_LISTINGS],
    onCompleted: () => {
      toast('Job deleted successfully!', 'success')
    },
    onError: () => {
      toast('Something went wrong, please try again.', 'error')
    },
  })

  const rows = loading ? (
    <></>
  ) : (
    jobsData.map((jobListing, index) => (
      <Table.Tr
        key={index}
        className="text-sm text-doubleNickel-gray-900"
        onClick={(event) => handleSelectedJob(event, jobListing)}
      >
        <Table.Td className="px-6">{jobListing.title}</Table.Td>
        <Table.Td className="px-6">
          <StyledBadge color="blue">{jobListing.status}</StyledBadge>
        </Table.Td>
        <Table.Td className="px-6">
          {formatDate(jobListing.jobPostingDate)}
        </Table.Td>
        <Table.Td className="px-6">
          <AvatarGroup
            avatars={jobListing.employees?.map((employee) => ({
              firstName: employee.firstName,
              lastName: employee.lastName,
            }))}
          />
        </Table.Td>
        <Table.Td className="px-6">
          {formatAddress(jobListing.address)}
        </Table.Td>
        <Table.Td className="flex flex-row items-center gap-5 px-4">
          <Tooltip label="See details" withArrow className="text-xs">
            <ActionIcon
              variant="subtle"
              className="h-6 w-6"
              onClick={(event) => handleSelectedJob(event, jobListing)}
            >
              <IconDocument className="fill-doubleNickel-white stroke-doubleNickel-brand-500" />
            </ActionIcon>
          </Tooltip>
          <Tooltip label="Go to applicants" withArrow className="text-xs">
            <Link
              to={routes.applicants({
                jobListingId: jobListing.jobListingId,
                jobListingTitle: jobListing.title,
              })}
            >
              <ActionIcon variant="subtle" className="h-6 w-6 ">
                <IconUsers className="fill-doubleNickel-white stroke-doubleNickel-brand-500" />
              </ActionIcon>
            </Link>
          </Tooltip>

          <Menu shadow="md" position="bottom-end">
            <Menu.Target>
              <Tooltip label={'More actions'} withArrow className="text-xs">
                <ActionIcon variant="subtle" className="no-trigger h-6 w-6">
                  <IconThreeDots className="fill-doubleNickel-white stroke-doubleNickel-gray-600" />
                </ActionIcon>
              </Tooltip>
            </Menu.Target>
            <Menu.Dropdown className="w-40">
              <Menu.Item
                className="text-doubleNickel-gray-700"
                onClick={(event) => handleMenu(event, jobListing)}
              >
                Delete
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
        </Table.Td>
      </Table.Tr>
    ))
  )

  return (
    <Table highlightOnHover stickyHeader>
      {openedDetails && (
        <JobListingDetailsModal
          job={selectedJob}
          opened={openedDetails}
          close={detailHandlers.close}
        />
      )}

      {selectedJob && (
        <DeleteModal
          title="Are you sure that you want to delete this job listing?"
          opened={openedDelete}
          close={deleteHandlers.close}
          onDelete={() =>
            deleteJobListing({
              variables: { id: selectedJob.jobListingId },
            })
          }
        />
      )}
      <Table.Thead>
        <Table.Tr className="h-11 border-none bg-doubleNickel-gray-50 text-xs text-doubleNickel-gray-600">
          <Table.Th className="w-[25rem] px-6 py-3">Job Title</Table.Th>
          <Table.Th className="w-[5rem] px-6 py-3">Status</Table.Th>
          <Table.Th className="w-[10rem] px-6 py-3">Posted Date</Table.Th>
          <Table.Th className="w-[10rem] px-6 py-3">Recruiters</Table.Th>
          <Table.Th className="w-[25rem] px-6 py-3">Location</Table.Th>
          <Table.Th className="w-11 px-6 py-3">Actions</Table.Th>
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>{rows}</Table.Tbody>
      <Table.Caption>
        {jobsData.length === 0 && !loading && (
          <div className="flex flex-1 flex-col items-center justify-center  ">
            <IconEmptyInbox className="h-40 fill-none" />
            <div className="text-md font-medium text-doubleNickel-gray-800">
              No Records Found
            </div>
            <div className="text-sm text-doubleNickel-gray-600">
              Create a new job listing to get started
            </div>
          </div>
        )}
      </Table.Caption>
    </Table>
  )
}

export default JobsListingTable
